import * as React from "react"
import { Input } from "@/components/ui/input"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { CONTRACT_TYPE, PAGINATION_SETTINGS } from "@/constants";
import { useQuery, useQueryClient } from "react-query";
import { useQueryState } from "./invoices.page";
import { useDebounce } from "@/components/ui/multiple-selector";
import { formatDateString } from "@/styles";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@tanstack/react-table"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { bulkBillsStateApprove, getBillExport, getBills, getUser } from "@/service/weexpertService";
import { v4 as uuidv4 } from 'uuid';
import { Skeleton } from "@/components/ui/skeleton";
import { DataTablePagination } from "@/components/ui/table-pagination";
import BillsFilter, { processBillFilter } from "@/components/popups/bill.filter";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";
import { useLocalStorageState } from "@/lib/useCookieState";
import FilterChips from "@/components/filter-chips/filter.chips";
import { processFilter } from "@/components/popups/invoice.filter.popup";

export function BillsPage(props) {

  const { t } = useTranslation();
  const [sharedFilter, setSharedFilter] = useQueryState('f', 'bills_filter')
  const [storedFilter, setStoredFilter] = useLocalStorageState('bills_filter');
  const [searchQuery, setSearchQuery] = useState(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [page, setPage] = useState(JSON.parse(sharedFilter ?? '{}').page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(JSON.parse(sharedFilter ?? '{}').rowsPerPage ?? PAGINATION_SETTINGS.bills);


  const [unprocessedFilter, setUnprocessedFilter] = useState(JSON.parse(storedFilter ?? '{}') ?? {});

  const [filters, setFilters] = useState(processBillFilter(unprocessedFilter));
  const [pagination, setPagination] = useState({ pageIndex: page, pageSize: rowsPerPage });
  const [selectedItems, setSelectedItems] = useState([]);
  const queryClient = useQueryClient()
  const { data: bills, isLoading, isFetched } = useQuery({
    queryKey: ['bills', page, rowsPerPage, debouncedSearchQuery, filters],
    queryFn: () => getBills(rowsPerPage, page * rowsPerPage, debouncedSearchQuery, Object.values(filters)),
  });


  useEffect(() => {
    setPage(pagination.pageIndex)
    setRowsPerPage(pagination.pageSize)
    setSharedFilter(JSON.stringify({ page: pagination.pageIndex, rowsPerPage: pagination.pageSize }))
  }, [pagination])

  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })

  const handleSelectAllChange = () => {
    if (selectedItems.length == bills?.data?.bills.length) {
      setSelectedItems([])
      return
    }
    setSelectedItems(bills?.data?.bills.map((item) => item.id));
  };

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      return prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : prevSelectedItems.concat(itemId);
    });
  };

  const columns = [
    {
      id: "select",
      header: () => {
        return user?.data?.roles !== 'CLIENT' ? <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedItems.length === bills?.data?.bills?.length && selectedItems.length !== 0}
          onCheckedChange={() => handleSelectAllChange()}
          aria-label="Select all"
        /> : ''
      },
      cell: ({ row }) => {
        return user?.data?.roles !== 'CLIENT' ? <Checkbox className='flex flex-row items-center justify-center py-0'
          checked={selectedItems.includes(row.original.id)}
          onCheckedChange={() => handleCheckboxChange(row.original.id)}
          aria-label="Select row"
        /> : ''
      },
      enableSorting: false,
      enableHiding: false
    },
    {
      accessorKey: "billId",
      header: t('bills:bills'),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {`${row.original.billId} `}
        </Link>
      },
      key: uuidv4()
    },
    {
      accessorKey: "contractID",
      header: t('bills:contract_id'),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {`${row.original.contract.contractId} `}
        </Link>
      },
      key: uuidv4()
    },
    {
      accessorKey: "client",
      header: t('client'),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {`${row.original.contract.client.firstName} ${row.original.contract.client.lastName}`}
        </Link>
      },
      key: uuidv4()

    },
    {
      accessorKey: "billType",
      header: t('bills:bill_type'),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {row.original.billType == CONTRACT_TYPE.UMOWA_O_DZIELO ? <Badge className={'bg-emerald-600'}> Umowa o dzielo </Badge> : <Badge > Umowa zlecenia</Badge>}
        </Link>
      },
      key: uuidv4()

    },
    {
      accessorKey: "createdAt",
      header: t("bills:created_at"),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {`${formatDateString(row.original.createdAt)} `}
        </Link>
      },
      key: uuidv4()
    },
    {
      accessorKey: "conclusionDate",
      header: t("bills:conclusion_date"),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {`${formatDateString(row.original.conclusionDate)} `}
        </Link>
      },
      key: uuidv4()
    },
    {
      accessorKey: "state",
      header: t("common:status"),
      cell: ({ row }) => (
        <div className={`capitalize w-24 ${row.original.state !== 'Accepted' ? 'bg-pirpleLightBg rounded text-pirpleText text-center' : 'bg-greenBgLite rounded text-center text-greenText'}`}>{row.original.billState != 'Accepted' ? t("invoices:status_in_progress") : t("invoices:status_complete")}</div>
      ),
      key: uuidv4()
    },
    {
      accessorKey: "grossAmount",
      header: t("bills:gross_amount"),
      cell: ({ row }) => {
        return <Link className="capitalize" to={`/bill/view/${row.original.id}`}>
          {`${row.original.grossAmount.toFixed(2)}`}
        </Link>
      },
      key: uuidv4()
    },
  ]
  useEffect(() => {
    setPage(0);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    setStoredFilter(unprocessedFilter);
    setFilters(processBillFilter(unprocessedFilter));
  }, [unprocessedFilter])

  const table = useReactTable({
    data: isLoading ? Array(rowsPerPage).fill({}) : bills.data.bills,
    columns: isLoading ? columns.map(column => {
      return {
        ...column,
        cell: () => <Skeleton className={"h-4 w-[150px]"} />
      }
    }) : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    rowCount: bills?.data.total ?? 0,
    state: {
      pagination
    },
    initialState: {
      pagination
    }
  });

  const handleDeleteFilter = (filterKey, value) => {
    setUnprocessedFilter((prev) => {
      const updatedFilter = { ...prev };
      if (updatedFilter[filterKey] && Array.isArray(updatedFilter[filterKey])) {
        updatedFilter[filterKey] = prev[filterKey].filter((e) => e.id !== value);
      } else if (Object.hasOwn(updatedFilter, filterKey)) {
        delete updatedFilter[filterKey]
      }
      return updatedFilter;
    });
  };



  const userRole = user.data.roles;
  return (
    <>
      <div className="relative">
        <div className="sticky left-0 top-16 z-10 w-full bg-grayLightMainBg">
          <div className="flex justify-between py-8 pr-8 items-center bg-grayLightMainBg">
            <div className="ml-8 text-4xl ">{t("bills:bills")}</div>
            <div className="flex w-5/6">
              <Input
                placeholder={t("search")}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mr-2 ml-8 bg-white"
              />
            </div>
            {!isUserLoading && user.data.roles !== 'CLIENT' && (
              <BillsFilter
                key={JSON.stringify(filters)}
                onFilterChanged={( unprocessedFilter) => {
                  setUnprocessedFilter(unprocessedFilter)
                  setStoredFilter(unprocessedFilter)
                  setSharedFilter(JSON.stringify({ page: 0, rowsPerPage: pagination.pageSize }))
                  setPagination({ pageIndex: 0, pageSize: rowsPerPage })
                }}
                currentFilter={unprocessedFilter}
                userData={props.userData}
              />
            )}
            {!isUserLoading && userRole !== "CLIENT" && selectedItems.length >= 1
              &&
              <Button className="text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                onClick={() => {
                  getBillExport(selectedItems)
                }}>
                Excel
              </Button>
            }
            {!isUserLoading && userRole !== "CLIENT" && selectedItems.length >= 1
              &&
              <Button className="ml-2 mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none"
                onClick={() => {
                  bulkBillsStateApprove(selectedItems)
                    .then(() => {
                      queryClient.invalidateQueries()
                    })
                }}>
                Approve
              </Button>
            }
            {!isUserLoading && user.data.roles !== 'CLIENT' && (
              <Link to='/bill/create'>
                <Button className="mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none">
                  {t("button_create")}
                </Button>
              </Link>
            )}
          </div>
        </div>
        <div className='w-full pt-4 px-6 flex flex-wrap'>
          <FilterChips
            unprocessedFilter={unprocessedFilter}
            handleDeleteFilter={handleDeleteFilter}
            skipList={['dateOfCreateFrom', 'dateOfCreateTo', 'startDateFrom', 'startDateTo', 'dateOfEndFrom', 'dateOfEndTo', 'dateOfConclusionFrom', 'dateOfConclusionTo']}
          />
        </div>


        <div className="w-full px-8 py-4 bg-grayLightMainBg">
          <div className="rounded-md border">
            {!isLoading && (
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map(headerGroup => (
                    <TableRow key={uuidv4()}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableHead className="pt-3 pb-3"
                            key={uuidv4()}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map(row => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map(cell => (
                          <TableCell className="relative text-sm pt-3 pb-3"
                            key={uuidv4()}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center m-[0px]"
                      >
                        {t("no_results")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          <DataTablePagination key={page + 'page'} table={table} />
        </div>
      </div>
    </>
  )
}

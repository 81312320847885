import { INVOICE_TYPE, REPRESENTATIVE_COMPANY_LIST, ROLES, STATUS, transformFromIdValueToValueLabel } from "@/constants";
import { getClients, getContractors, getUser, getUsers } from "@/service/weexpertService";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { BaseDialog } from "./dialog";
import MultipleSelector from "../ui/multiple-selector";
import { DatePicker } from "../ui/date-picker";
import { parseISO, format } from 'date-fns';
import { useQuery } from "react-query";
import { CheckIcon } from "@radix-ui/react-icons";
import MultipleSelectorWithSearch from "../ui/multiple-selector-with-search";

const transformAutocompleteValueToFilter = (valueArray, field) => {
  return {
    field: field,
    value: valueArray.map((e) => e.id),
  };
};
export function processFilter(unprocessFilter) {
  let newFilters = {};


  if (unprocessFilter.selectedClients?.length > 0) {
    newFilters = {
      ...newFilters,
      client: transformAutocompleteValueToFilter(unprocessFilter.selectedClients, "client"),
    };
  }


  if (unprocessFilter.selectedAssistant?.length > 0) {
    newFilters = {
      ...newFilters,
      assistant: transformAutocompleteValueToFilter(unprocessFilter.selectedAssistant, "assistant"),
    };
  }


  if (unprocessFilter.selectedCounterparties?.length > 0) {
    newFilters = {
      ...newFilters,
      counterparties: transformAutocompleteValueToFilter(unprocessFilter.selectedCounterparties, "counterparties"),
    };
  }

  if (unprocessFilter.selectedInvoiceType?.length > 0) {
    newFilters = {
      ...newFilters,
      invoiceType: transformAutocompleteValueToFilter(unprocessFilter.selectedInvoiceType, "invoiceType"),
    };
  }

  if (unprocessFilter.states?.length > 0) {
    newFilters = {
      ...newFilters,
      state: transformAutocompleteValueToFilter(unprocessFilter.states, "state"),
    };
  }

  if (unprocessFilter.representativeCompany?.length > 0) {
    newFilters = {
      ...newFilters,
      representativeCompany: transformAutocompleteValueToFilter(
        unprocessFilter.representativeCompany,
        "representativeCompany"
      ),
    };
  }

  const dateOfCreatePeriod = unprocessFilter.dateOfCreatePeriod ?? [];
  const domainDatePeriod = unprocessFilter.domainDatePeriod ?? [];
  newFilters = {
    ...newFilters,
    createdAt: {
      field: 'createdAt',
      value: dateOfCreatePeriod.filter(e => e).length == 2 ?
      dateOfCreatePeriod.filter(e => e).map(e => format(new Date(e), 'yyyy-MM-dd')) : []
    },
  };

  newFilters = {
    ...newFilters,
    domainDate: {
      field: 'domainDate',
      value: domainDatePeriod.filter(e => e).length == 2 ?
      domainDatePeriod.filter(e => e)
        .map(e => format(new Date(e), 'yyyy-MM-dd')) : []
    },
  };

  //console.log("newFilters --->", JSON.stringify(newFilters, null, 2));
  //console.log("domainDatePeriod:", domainDatePeriod);
  //console.log("dateOfCreatePeriod:", dateOfCreatePeriod);

  return newFilters;
}


export const InvoiceFilter = ({ onFilterChange, currentFilter }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState([]);
  const [unprocessedFilters, setUnprocessedFilter] = useState({
    selectedClients: [],
    selectedAssistant: [],
    selectedCounterparties: [],
    invoicesType: [],
    invoiceStates: [],
    domainDatePeriod:[],
    dateOfCreatePeriod:[],
    domainDateFrom:null,
    domainDateTo:null,
    dateOfCreateFrom:null,
    dateOfCreateTo:null

  });
  const transformAutocompleteValueToFilter = (valueArray, field) => {
    return {
      field: field,
      value: valueArray.map(e => e.id)
    }
  }

  const { data: user, isLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })

  const [selectedClients, setSelectedClients] = useState(currentFilter.selectedClients ?? []);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(currentFilter.selectedInvoiceType ?? []);
  const [selectedCounterparties, setSelectorCounterparties] = useState(currentFilter.selectedCounterparties ?? []);
  const [states, setStates] = useState(currentFilter.states ?? []);
  const [dateOfCreateFrom, setDateOfCreateFrom] = useState(currentFilter.dateOfCreateFrom ? new Date(currentFilter.dateOfCreateFrom) : null);
  const [dateOfCreateTo, setDateOfCreateTo] = useState(currentFilter.dateOfCreateTo ? new Date(currentFilter.dateOfCreateTo) : null);
  const [dateOfCreatePeriod, setDateOfCreatePeriod] = useState([dateOfCreateFrom, dateOfCreateTo]); //createInvoice period
  const [domainDateFrom, setDomainDateFrom] = useState(currentFilter.domainDateFrom ? new Date(currentFilter.domainDateFrom) : null);
  const [domainDateTo, setDomainDateTo] = useState(currentFilter.domainDateTo ? new Date(currentFilter.domainDateTo) : null);
  const [domainDatePeriod, setDomainDatePeriod] = useState([domainDateFrom, domainDateTo]);   //domainInvoice period
  const [selectedAssistant, setSelectedAssistant] = useState(currentFilter.selectedAssistant ?? []);
  const [representativeCompany, setRepresentativeCompany] = useState([]);

  useEffect(() => {
    setDateOfCreatePeriod([dateOfCreateFrom, dateOfCreateTo])
    console.log("Updated dateOfCreatePeriod:", [dateOfCreateFrom, dateOfCreateTo]);
  }, [dateOfCreateFrom, dateOfCreateTo])

  useEffect(() => {
    setDomainDatePeriod([domainDateFrom, domainDateTo])
    console.log("Updated domainDatePeriod:", [domainDateFrom, domainDateTo]);
  }, [domainDateFrom, domainDateTo])

  useEffect(() => {
    const clientsFilter = transformAutocompleteValueToFilter(selectedClients, 'client');
    const counterpartiesFilter = transformAutocompleteValueToFilter(selectedCounterparties, 'counterparties');
    const stateFilter = transformAutocompleteValueToFilter(states, 'state');
    const invoiceType = transformAutocompleteValueToFilter(selectedInvoiceType, 'invoiceType')
    const assistantFilter = transformAutocompleteValueToFilter(selectedAssistant, 'assistant')
    const representativeCompanyFilter = transformAutocompleteValueToFilter(representativeCompany, 'representativeCompany')
    setUnprocessedFilter({
      selectedClients,
      selectedAssistant,
      selectedCounterparties,
      selectedInvoiceType,
      dateOfCreateTo,
      states,
      dateOfCreateFrom,
      domainDateFrom,
      domainDateTo,
      representativeCompany
    })

    setFilters({
      client: clientsFilter,
      counterparies: counterpartiesFilter,
      stateFilter: stateFilter,
      invoiceType: invoiceType,
      domainDate: {
        field: 'domainDate',
        value: domainDatePeriod.filter(e => e).length == 2 ?
          domainDatePeriod.filter(e => e).map(e => format(e, 'yyyy-MM-dd')) : []
      },
      createdAt: {
        field: 'createdAt',
        value: dateOfCreatePeriod.filter(e => e).length == 2 ?
          dateOfCreatePeriod.filter(e => e).map(e => format(e, 'yyyy-MM-dd')) : []
      },
      assistant: assistantFilter,
      representativeCompanyFilter: representativeCompanyFilter
    });
  }, [selectedClients,
    states,
    selectedCounterparties,
    selectedInvoiceType,
    domainDatePeriod,
    dateOfCreatePeriod,
    selectedAssistant,
    representativeCompany]);

  const [open, setOpen] = useState(false);

  const isFilterNotEmpty = (filterData) => {
    return Object.values(filterData).some(value => {
      if (Array.isArray(value)) {
        return value.some(item => item !== null);
      } else {
        return value !== null && value !== undefined;
      }
    });
  };
 


  useEffect(() => {
    const up = {
      selectedClients,
      selectedAssistant,
      selectedCounterparties,
      selectedInvoiceType,
      states,
      representativeCompany,
      domainDatePeriod,
      dateOfCreatePeriod,
      domainDateTo,
      domainDateFrom,
      dateOfCreateTo,
      dateOfCreateFrom
    };
    setUnprocessedFilter(up);
    setFilters(processFilter(up));
  }, [
    selectedClients,
    selectedAssistant,
    selectedCounterparties,
    selectedInvoiceType,
    states,
    representativeCompany,
    domainDatePeriod,
    dateOfCreatePeriod,
    dateOfCreateFrom,
    dateOfCreateTo,
    domainDateTo,
    domainDateFrom
  ]
  );

  return <BaseDialog
    dialogTitle={t('filter')}
    buttonText={t('filter')}
    open={open}
    setOpen={(open) => {
      setOpen(open)
      if (!open) {
        onFilterChange(filters, unprocessedFilters)
      }
    }
    }
    icon={isFilterNotEmpty(currentFilter) ? <CheckIcon /> : null}
    ommitStyles={isFilterNotEmpty(currentFilter)}
  >
    <div className="w-[500px] px-2">
      <div>
        <MultipleSelectorWithSearch
          fetchOption={(q) => {
            return getClients(10, 0, q)
              .then((result) => {
                return result.data.clients
                  .map(client => {
                    return {
                      label: `${client.firstName} ${client.lastName}`,
                      value: `${client.firstName} ${client.lastName}`,
                      id: client.id
                    }
                  })
              });
          }}
          className="min-h-10 mt-1 mb-2"
          placeholder={t("selected")}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedClients}
          label={t("clients")}
          onChange={(newSelected) => {
            setSelectedClients(newSelected)
          }}
        />
      </div>
      <div>
        <MultipleSelector
          className="min-h-10 mt-1 mb-2"
          placeholder={t("selected")}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedInvoiceType}
          label={t("type")}
          onChange={(newSelected) => {
            setSelectedInvoiceType(newSelected)
          }}
          options={INVOICE_TYPE(t)
            .map(transformFromIdValueToValueLabel)
            .map(item => {
              return {
                id: item.value,
                value: item.label,
                label: item.label
              }
            }
            )}
        />
      </div>
      <div>
        <MultipleSelectorWithSearch
          fetchOption={(q) => {
            return getContractors(20, 0, q)
              .then((result) => {
                return result.data.counterparties
                  .map(counterparty => {
                    return {
                      value: `${counterparty.name}`,
                      label: counterparty.name,
                      id: counterparty.name,
                    }
                  });
              })
          }}
          className="min-h-10 mt-1 mb-2"
          placeholder={t('selected')}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedCounterparties}
          label={t("counterparty")}
          onChange={(newSelected) => {
            setSelectorCounterparties(newSelected)
          }}
        />
      </div>
      <div>
        <MultipleSelector
          className="min-h-10 mt-1 mb-2"
          placeholder={t('selected')}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={states}
          label={t("status")}
          onChange={(newSelected) => {
            setStates(newSelected)
          }}
          options={STATUS(t)
            .map(transformFromIdValueToValueLabel)
            .map(item => {
              return {
                id: item.value,
                value: item.label,
                label: item.label
              }
            }
            )}
        />
      </div>
      <div>
        <MultipleSelector
          className="min-h-10 mt-1 mb-2"
          placeholder={t('selected')}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={representativeCompany}
          label={t("representative_company")}
          onChange={(newSelected) => {
            setRepresentativeCompany(newSelected)
          }}
          options={REPRESENTATIVE_COMPANY_LIST
            .map(transformFromIdValueToValueLabel)
            .map(item => {
              return {
                id: item.value,
                value: item.label,
                label: item.label
              }
            })
          }
        />
      </div>
      {([ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.HEAD_OF_DEPARTMENT].includes(user.data.roles)) && (
        <div>
          <MultipleSelectorWithSearch
            fetchOption={(q) => {
              return getUsers(20, 0, q)
                .then((result) => {
                  return result.data.users
                    .map(user => {
                      return {
                        label: `${user.firstName} ${user.lastName}`,
                        value: `${user.firstName} ${user.lastName}`,
                        id: user.id
                      }
                    })
                });
            }}
            className="min-h-10 mt-1 mb-2"
            placeholder={t('selected')}
            badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
            value={selectedAssistant}
            label={t("assistants")}
            onChange={(newSelected) => {
              setSelectedAssistant(newSelected)
            }}
          />
        </div>
      )}
      <div className="flex justify-between pb-4">
        <div className="w-[48%]">
          <DatePicker date={dateOfCreateFrom}
            setDate={(e) => setDateOfCreateFrom(e)}
            label={t("invoices:date_of_creation_from")}
          />
        </div>
        <div className="w-[48%]">
          <DatePicker date={dateOfCreateTo}
            setDate={(e) => setDateOfCreateTo(e)}
            label={t("invoices:date_of_creation_to")}
          />
        </div>

      </div>
      <div className="flex justify-between ">
        <div className="w-[48%]">
          <DatePicker date={domainDateFrom}
            setDate={(e) => setDomainDateFrom(e)}
            label={t("invoices:date_of_sale_from")}
          />
        </div>
        <div className="w-[48%]">
          <DatePicker date={domainDateTo}
            setDate={(e) => setDomainDateTo(e)}
            label={t("invoices:date_sale_to")}
          />
        </div>
      </div>

      <div className="w-full flex justify-end pt-6">
        <div className="w-full flex justify-end">
          <Button className="mr-4" variant="outline" onClick={() => {
            onFilterChange([], {})
            setOpen(false)
          }}>{t("common:button_clean_filter")}</Button>
          <Button className="" onClick={() => {
            onFilterChange(filters, unprocessedFilters)
            setOpen(false)
          }}>{t("common:button_save")}</Button>
        </div>
      </div>
    </div>
  </BaseDialog>
}

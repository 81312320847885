import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultipleSelector from "../ui/multiple-selector";
import { BaseDialog } from "./dialog";
import { Button } from "@/components/ui/button";
import { getUsers } from "@/service/weexpertService";
import { SelectWithAlert } from "../ui/select-with-alert";
import { ACTIVE_STATUS, REPRESENTATIVE_COMPANY_LIST } from "@/constants";
import { CheckIcon } from "@radix-ui/react-icons";
import { Checkbox } from "../ui/checkbox";
import { Label } from "../ui/label";

const transformAutocompleteValueToFilter = (valueArray, field) => {
  return {
    field: field,
    value: valueArray.map((e) => e.id),
  };
};
export function processClinetFilter(unprocessFilter) {
  const usersFilter =
    unprocessFilter.selectedUsers?.length > 0
      ? transformAutocompleteValueToFilter(
          unprocessFilter.selectedUsers,
          "assistant",
        )
      : null;

  let isActive = null;
  if (unprocessFilter.currentStatus !== "all") {
    isActive = transformAutocompleteValueToFilter(
      [{ id: unprocessFilter.currentStatus }],
      "isActive",
    );
  }

  const concent = unprocessFilter.profile
    ? { field: "concent", value: [true, false] }
    : null;

  let newFilters = {
    ...(isActive && { isActive }),
    ...(usersFilter && { user: usersFilter }),
  };

  if (unprocessFilter.representativeCompany !== "") {
    const representativeCompanyFilter = {
      field: "representativeCompany",
      value: [unprocessFilter.representativeCompany],
    };
    newFilters = {
      ...newFilters,
      representativeCompanyFilter,
    };
  }

  if (unprocessFilter.profile) {
    newFilters = {
      ...newFilters,
      concent,
    };
  }

  return newFilters;
}
export default function ClientFilter(props) {
  const { t } = useTranslation();
  const { currentFilter, userData } = props;
  const role = userData.roles;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [unprocessedFilters, setUnprocessedFilter] = useState({
    selectedUsers: [],
    currentStatus: false,
  });

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    currentFilter.selectedUsers ?? [],
  );
  const [currentStatus, setCurrentStatus] = useState(
    currentFilter.currentStatus ?? null,
  );
  const [representativeCompany, setRepresentativeCompany] = useState(
    currentFilter.representativeCompany ?? "",
  );
  const [profile, setProfile] = useState(currentFilter.profile ?? null);

  useEffect(() => {
    const up = {
      selectedUsers,
      currentStatus,
      representativeCompany,
      profile,
    };
    setUnprocessedFilter(up);
    setFilters(processClinetFilter(up));
  }, [selectedUsers, currentStatus, representativeCompany, profile]);

  useEffect(() => {
    if (role !== "ASSISTANT" && role !== "CLIENT") {
      getUsers(1000, 0).then((result) => {
        setUsers(
          result.data.users.map((user) => {
            return {
              id: user.id,
              value: `${user.firstName} ${user.lastName}`,
              label: `${user.firstName} ${user.lastName}`,
            };
          }),
        );
      });
    }
  }, [role]);

  const isFilterNotEmpty = (filterData) => {
    return (
      Object.values(filterData).filter((e) => e && e && e.length > 0).length > 0
    );
  };

  return (
    <>
      <BaseDialog
        dialogTitle={t("filter")}
        buttonText={t("filter")}
        open={open}
        setOpen={(open) => {
          setOpen(open);
          if (!open) {
            // if closed we want to trigger filters
            props.onFilterChanged(filters, unprocessedFilters);
          }
        }}
        icon={isFilterNotEmpty(currentFilter) ? <CheckIcon /> : null}
        ommitStyles={isFilterNotEmpty(currentFilter)}
      >
        <div className="w-[500px] pt-1 pb-[80px] px-2">
          <MultipleSelector
            className="min-h-12 mt-2 "
            placeholder={t("selected")}
            badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
            value={selectedUsers}
            label={t("assistant")}
            readOnly={false}
            onChange={(newSelected) => {
              setSelectedUsers(newSelected);
            }}
            options={users}
          />
          <SelectWithAlert
            value={currentStatus}
            onChangeValue={(e) => setCurrentStatus(e)}
            label={t("status")}
            options={ACTIVE_STATUS(t).map((i) => {
              return {
                value: i.id,
                label: i.value,
                id: i.id,
              };
            })}
          />
          <SelectWithAlert
            value={representativeCompany}
            onChangeValue={(e) => setRepresentativeCompany(e)}
            label={t("clients:representative_company")}
            options={REPRESENTATIVE_COMPANY_LIST.map((i) => {
              return {
                value: i.id,
                label: i.value,
                id: i.id,
              };
            })}
          />
          {/*
          <div className="flex items-center pt-3">
            <Checkbox
              checked={profile}
              onCheckedChange={(t) => setProfile(t)}
              className="mr-2"
            />
            <Label>{t("onboarding_profile")}</Label>
          </div>
          */}
        </div>
        <div className="w-full flex flex-col items-end">
          <div className="w-full flex justify-end">
            <Button
              className="mr-4"
              variant="outline"
              onClick={() => {
                props.onFilterChanged([], {});
                setOpen(false);
              }}
            >
              {t("common:button_clean_filter")}
            </Button>
            <Button
              onClick={() => {
                props.onFilterChanged(filters, unprocessedFilters);
                setOpen(false);
              }}
            >
              {t("button_save")}
            </Button>
          </div>
        </div>
      </BaseDialog>
    </>
  );
}

import { getVatReportForClients } from "@/service/weexpertService";
import dayjs from "dayjs";
import { DownloadIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialog } from "./dialog";
import { Button } from "../ui/button";
import { SelectWithAlert } from "../ui/select-with-alert";
import { toast } from "../ui/use-toast";
import { REPRESENTATIVE_COMPANY_LIST, transformFromIdValueToValueLabel, WEEXPERT } from "@/constants";


export const ClientsVatReportPopup = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false)
  const currentDate = dayjs();
  const initialMonth = currentDate.month() + 1;
  const initialYear = currentDate.year();
  const [month, setMonth] = useState(initialMonth);
  const [year, setYear] = useState(initialYear);
  const [representativeCompany, setRepresentativeCompany] = useState(WEEXPERT);
  return <BaseDialog
    dialogTitle={"Clients vat report"}
    buttonText={"Clients vat report"}
    open={open}
    setOpen={setOpen}
  >
    <div className="w-[500px] pt-6">
      <SelectWithAlert value={month} onChangeValue={(e) => setMonth(e)}
        label={t("invoices:vat_placeholder_month")}
        options={[...Array(12).keys()].map(i => {
          return {
            value: i + 1,
            label: i + 1
          };
        })}
      />
      <SelectWithAlert value={year} onChangeValue={(e) => setYear(e)}
        label={t("invoices:vat_placeholder_year")}
        options={[...Array(12).keys()].map(i => {
          return {
            value: i + 2023,
            label: i + 2023
          };
        })}
      />
      <SelectWithAlert
        value={representativeCompany}
        onChangeValue={(e) => setRepresentativeCompany(e)}
        label={t("representative_company")}
        options={REPRESENTATIVE_COMPANY_LIST.map(transformFromIdValueToValueLabel)}
      />

      <div className="w-full flex flex-col items-end">
        <Button className='mt-4' onClick={() => {
          toast({
            title: t("download")
          })
          setOpen(false)
          getVatReportForClients(month, year, representativeCompany).then(() => {
            toast({
              title: t("download")
            })
          })
            .catch((e) => {
              toast({
                title: t("Oops"),
                variant: 'destructive'
              })
            })

        }} ><DownloadIcon className="h-[16px] mr-2" />{t("common:download")}</Button>
      </div>
    </div>
  </BaseDialog >
}

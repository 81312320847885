import { INVOICE_TYPE, parseNumber, REPRESENTATIVE_COMPANY_LIST, ROLES, STATUS } from "@/constants";
import { getClients, getContractors, getUser, getUsers } from "@/service/weexpertService";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { BaseDialog } from "./dialog";
import MultipleSelector from "../ui/multiple-selector";
import { DatePicker } from "../ui/date-picker";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { CheckIcon } from "@radix-ui/react-icons";
import MultipleSelectorWithSearch from "../ui/multiple-selector-with-search";
import { JOB_TITLE_OPTIONS, TOOLS_SKILLS } from "@/profile.constants";
import { MONTHLY_RATE as MONTHLY_RATES } from "@/profile.constants";
import { InputWithAlert } from "../ui/input-with-alert";
import { Combobox } from "../ui/combobox";
export const processCandidateFilter = (filter) => {
  const transformAutocompleteValueToFilter = (valueArray, field) => {
    return {
      field: field,
      value: valueArray.map((e) => e.value),
    };
  };
  return {
    jobTitles: transformAutocompleteValueToFilter(filter.jobTitles ?? [], 'jobTitles'),
    knowledges: transformAutocompleteValueToFilter(filter.tools ?? [], 'knowledges'),
    monthlyRates: transformAutocompleteValueToFilter(filter.monthlyRate ?? [], 'monthlyRates'),
    experience: {
      field: 'experience',
      value: [filter.experience]
    },
  }
}
export const CandidatesFilter = ({ onFilterChange, currentFilter }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [jobTitles, setJobTitles] = useState(currentFilter?.jobTitles ?? []);
  const [tools, setTools] = useState(currentFilter?.tools ?? []);
  const [experience, setExperience] = useState(currentFilter?.experience ?? undefined);

  /*
  const [hourlyRateFrom, setHourlyRateFrom] = useState(currentFilter?.hourlyRatePeriod[0] ?? undefined);
  const [hourlyRateTo, setHourlyRateTo] = useState(currentFilter?.hourlyRatePeriod[1] ?? undefined);
  const [hourlyRatePeriod, setHourlyRatePeriod] = useState([]);
  */

  const [monthlyRate, setMonthlyRate] = useState(currentFilter?.monthlyRate ? currentFilter?.monthlyRate[0] : undefined);
  const updateFilter = () => {
    let filters = {
      jobTitles,
      tools,
      experience,
      //hourlyRatePeriod,
    };
    if (monthlyRate) {
      filters = {
        ...filters,
        monthlyRate: [monthlyRate] // Nothing is more permanent than temporary solution 
      }
    }
    onFilterChange(filters);
  }
  /*
  useEffect(() => {
    const normalizeValue = (value) => value || value === 0 ? value : null;
    setHourlyRatePeriod([
      normalizeValue(hourlyRateFrom),
      normalizeValue(hourlyRateTo)
    ]);
  }, [
    hourlyRateFrom,
    hourlyRateTo
  ]);
  */

  return (
    <BaseDialog
      dialogTitle={t('filter')}
      buttonText={t('filter')}
      open={open}
      setOpen={
        (open) => {
          setOpen(open)
          if (!open) {
            updateFilter()
          }
        }
      }
    >
      <div className="w-[500px] py-6">
        <div className="w-full">
          <div className="pb-10">

            <MultipleSelector
              options={JOB_TITLE_OPTIONS}
              label={"Job titles/roles"}
              errorMessage={t("empty_data_invalid")}
              badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
              value={jobTitles}
              onChange={(newSelected) => {
                setJobTitles(newSelected)
              }}
              placeholder={"Job titles/roles"}
              inputClassName={"h-12 bg-white"}
            />
            <MultipleSelector
              options={TOOLS_SKILLS}
              label={"Technology stack/tools"}
              placeholder={"Technology stack/tools"}
              badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
              errorMessage={t("empty_data_invalid")}
              value={tools}
              onChange={(e) => {
                setTools(e);
              }}
              inputClassName={"h-12 mb-4 bg-white "}
            />
            <div className="pb-2">
              <Combobox
                className={"my-2 h-10 mb-5"}
                key={monthlyRate?.value}
                label={"Monthly rate from (USD)"}
                placeholder={"Monthly rate from (USD)"}
                creatable
                value={monthlyRate}
                errorMessage={t("empty_data_invalid")}
                options={MONTHLY_RATES}
                onChange={(newValue) => {
                  console.log(newValue)
                  setMonthlyRate(newValue);
                }}
              />
            </div>
            <div className="pb-2">
              <InputWithAlert
                value={experience}
                onChange={(e) => {
                  const value = parseNumber(e.target.value);
                  if (value <= 40) {
                    setExperience(value);
                  }
                }}
                label={"Experience From"}
                inputClassName={"h-10"}
              />
            </div>
            {/*
            <div className="flex justify-between pb-2">
              <div className="w-[48%]">
                <InputWithAlert
                  value={hourlyRateFrom}
                  onChange={(e) => {
                    const value = parseNumber(e.target.value);
                    if (value <= 200) {
                      setHourlyRateFrom(value);
                    }
                  }}
                  label={"Hourly rate From"}
                  inputClassName={"h-10"}
                />
              </div>
              <div className="w-[48%]">
                <InputWithAlert
                  value={hourlyRateTo}
                  onChange={(e) => {
                    const value = parseNumber(e.target.value);
                    if (value <= 200) {
                      setHourlyRateTo(value);
                    }
                  }}
                  label={"Hourly rate To"}
                  inputClassName={"h-10"}
                />
              </div>
            </div>
            */}
          </div>
          <div className="w-full flex justify-end">
            <Button className="mr-4" variant="outline" onClick={() => {
              setOpen(false)
            }}>{t("common:button_clean_filter")}</Button>
            <Button className="" onClick={() => {
              updateFilter();
              setOpen(false);
            }}>{t("common:button_save")}</Button>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

import Cookies from "js-cookie";
export const useLocalStorageState = (cookiesKey)=> {
  const getSavedCookie = (cookiesKey) => {
    return localStorage.getItem(cookiesKey) ?? '{}';
  };
  const setQuery = (value) => {
    localStorage.setItem(cookiesKey, `${JSON.stringify(value)}`);
  };
  return [getSavedCookie(cookiesKey), setQuery];
};

import React, { useTransition } from "react";
import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { hasTransaltionKey, translateKey } from "@/constants";
import { format } from "date-fns";

const FilterChips = ({
  unprocessedFilter,
  handleDeleteFilter,
  skipList = []
}) => {
  const { t } = useTranslation();

  // for lists of type is not array or boolean
  function getValueById(list, key) {
    const item = list.find((entry) => entry.id === key);
    return item ? item.value : key;
  }
  const isValidDateValue = (value) => {
    if (Array.isArray(value) && value.length === 2) {
      try {
        for (const val of value) {
          if (isNaN((new Date(val)).getTime())) {
            return false
          }
        }
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }
  function isValidISODate(dateString) {
    if (dateString instanceof Date) {
      return true;
    }
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date) && date.toISOString() === dateString;
  }
  const formatDate = (data) => {
    return format(new Date(data), 'yyyy-MM-dd');

  }
  const shouldSkip = (key, value) => {
    const isEmptyArray = (value) => {
      return Array.isArray(value) && (value.length === 0 || value.filter(e => !!e).length == 0);
    };
    const isNotFullPeriod = (value) => {
      return (
        Array.isArray(value) && value.length === 2 && (!!!value[0] || !!!value[1])
      );
    };
    return !value || isEmptyArray(value) ||
      isNotFullPeriod(value) ||
      skipList.includes(key);
  };
  return (
    <>
      {Object.entries(unprocessedFilter ?? []).map(([key, value]) => {
        if (shouldSkip(key, value)) {
          return null;
        }

        if (
          Array.isArray(value) &&
          !isValidDateValue(value)
        ) {
          return value.map((item, index) => (
            <div
              className="ml-2 p-2 flex items-center rounded-md bg-blueLightAccent hover:bg-blueLightAccent border mb-2 text-sm text-blueText hover:border-blueBorder"
              key={`${key}-${index}`}
            >
              <div className="pr-2">{item.value}</div>
              <div onClick={() => handleDeleteFilter(key, item.id || item)}>
                <X className="h-4 w-4 cursor-pointer" />
              </div>
            </div>
          ));
        }

        if (isValidDateValue(value)) {
          return (
            <div
              className="ml-2 p-2 flex items-center rounded-md bg-blueLightAccent hover:bg-blueLightAccent border mb-2 text-sm text-blueText hover:border-blueBorder"
              key={`${key}`}
            >
              <div className="font-bold pr-2">{
                hasTransaltionKey(t, key) ? translateKey(t, key) : key
              }</div>
              <div className="pr-2">
                {`${format(new Date(value[0]), 'yyyy-MM-dd')}  ${format(new Date(value[1]), 'yyyy-MM-dd')}`}
              </div>
              <div onClick={() => handleDeleteFilter(key)}>
                <X className="h-4 w-4 cursor-pointer" />
              </div>
            </div>
          );
        }

        if (typeof value === "boolean") {
          return (
            <div
              className="ml-2 p-2 flex items-center rounded-md bg-blueLightAccent hover:bg-blueLightAccent border mb-2 text-sm text-blueText hover:border-blueBorder"
              key={key}
            >
              <div className="pr-2">
                <span>
                  {`${translateKey(t, key)}`}:
                </span>

                {`${value ? t("active") : t("unactive")}`}
              </div>
              <div onClick={() => handleDeleteFilter(key)}>
                <X className="h-4 w-4 cursor-pointer" />
              </div>
            </div>
          );
        }
        if (isValidISODate(value)) {
          return (
            <div
              className="ml-2 p-2 flex items-center rounded-md bg-blueLightAccent hover:bg-blueLightAccent border mb-2 text-sm text-blueText hover:border-blueBorder"
              key={key}
            >
              <div className="pr-2">
                {hasTransaltionKey(t, key) && (
                  <span>
                    {`${translateKey(t, key)}`}:
                  </span>
                )}
                {' ' + formatDate(value)}
              </div>
              <div onClick={() => handleDeleteFilter(key)}>
                <X className="h-4 w-4 cursor-pointer" />
              </div>
            </div>
          );
        }

        return (
          <div
            className="ml-2 p-2 flex items-center rounded-md bg-blueLightAccent hover:bg-blueLightAccent border mb-2 text-sm text-blueText hover:border-blueBorder"
            key={key}
          >
            <div className="pr-2">
              {value}
            </div>
            <div onClick={() => handleDeleteFilter(key)}>
              <X className="h-4 w-4 cursor-pointer" />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FilterChips;

import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { batchMarkMatched, getUser } from "@/service/weexpertService";
import { Cross1Icon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { BatchExplanationCreate } from "@/components/popups/batch-explanation.popup";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { useDebounce } from "@/components/ui/multiple-selector";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { DataTablePagination } from "@/components/ui/table-pagination";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { BookCheck, BookX, CheckIcon, XIcon } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useQueryState } from "../invoices.page";
import { useTranslation } from "react-i18next";
import { Loader } from "@/components/ProtectedRoute";
import { ROLES } from "@/constants";

export const TransactionsTable = (props) => {
  const {t} = useTranslation();

  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })
  const { title } = props;
  const { filterCacheKey } = props;
  const [sharedFilter, setSharedFilter] = useQueryState(filterCacheKey);
  const { translateForTransactionsTable, toggleMatch } = props;
  const { reportId, fetchFunction, cacheKey } = props;

  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const parsedSharedFilters = JSON.parse(sharedFilter ?? "{}");
  const [page, setPage] = useState(parsedSharedFilters.page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    parsedSharedFilters.rowsPerPage ?? 50,
  );
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: rowsPerPage,
  });

  const [filteredAccounts, setFilteredAccounts] = useState(
    parsedSharedFilters.accounts ?? [],
  );
  const [filteredAccount, setFilteredAccount] = useState("");
  const [searchQuery, setSearchQuery] = useState(
    parsedSharedFilters.search ?? "",
  );
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const getViewUrl = (id) => {
    return `/report/transaction/${id}`;
  };
  const handleCheckboxChange = (id) => {
    if (selectedTransaction.includes(id)) {
      setSelectedTransaction((rev) => {
        return rev.filter((i) => i != id);
      });
      return;
    }
    setSelectedTransaction((rev) => {
      return rev.concat(id);
    });
  };
  const handleSelectAllChange = () => {
    if (
      selectedTransaction.length == transactionsData?.data.transactions.length
    ) {
      setSelectedTransaction([]);
      return;
    }
    setSelectedTransaction(
      transactionsData?.data.transactions.map((item) => item.id),
    );
  };
  useEffect(() => {
    setPage(pagination.pageIndex);
    setRowsPerPage(pagination.pageSize);
    setSharedFilter(
      JSON.stringify({
        accounts: filteredAccounts,
        search: searchQuery,
        page: pagination.pageIndex,
        rowsPerPage: pagination.pageSize,
      }),
    );
  }, [pagination]);

  useEffect(() => {
    setSharedFilter(
      JSON.stringify({
        accounts: filteredAccounts,
        search: searchQuery,
        page: pagination.pageIndex,
        rowsPerPage: pagination.pageSize,
      }),
    );
  }, [searchQuery, filteredAccounts]);

  const columns = [
    {
      id: "select",
      header: () => (
        <Checkbox
          className="flex flex-row items-center justify-center py-0"
          checked={
            selectedTransaction.length ===
              transactionsData?.data?.transactions?.length &&
            transactionsData.length !== 0
          }
          onCheckedChange={() => handleSelectAllChange()}
          aria-label="Select all"
        />
      ),

      cell: ({ row }) => (
        <Checkbox
          className="flex flex-row items-center justify-center py-0"
          checked={selectedTransaction.includes(row.original.id)}
          onCheckedChange={() => handleCheckboxChange(row.original.id)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: "id",
      header: translateForTransactionsTable("reports:transaction_ID"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.transactionID}
        </Link>
      ),
    },
    {
      accessorKey: "amount",
      header: translateForTransactionsTable("invoices:price_brutto"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.amount}
        </Link>
      ),
    },
    {
      accessorKey: "currency",
      header: translateForTransactionsTable("invoices:currency"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.currency}
        </Link>
      ),
    },
    {
      accessorKey: "Account",
      header: translateForTransactionsTable("invoices:payment_account"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.accountNumber}
        </Link>
      ),
    },
    {
      accessorKey: "Booking_date",
      header: translateForTransactionsTable("reports:booking_date"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {format(new Date(row.original.bookingDate ?? null), "dd.MM.yyyy")}
        </Link>
      ),
    },
    {
      accessorKey: "client",
      header: t("common:client"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.client ? (
            <>
              {" "}
              <h3>
                {row.original.client.firstName} {row.original.client.lastName}
              </h3>
            </>
          ) : (
            <XIcon />
          )}
        </Link>
      ),
    },

    {
      accessorKey: "Coounerparty",
      header: translateForTransactionsTable("counterparty"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.contractor}
        </Link>
      ),
    },
    {
      accessorKey: "Invoice",
      header: t("common:invoice"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.invoice ? row.original.invoice.invoiceId : <XIcon />}
        </Link>
      ),
    },

    {
      accessorKey: "shared",
      header: "Shared",
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.shared ? <CheckIcon /> : <XIcon />}
        </Link>
      ),
    },
    {
      accessorKey: "Matched",
      header: translateForTransactionsTable("reports:matched"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.matched ? <BookCheck /> : <BookX />}
        </Link>
      ),
    },
    {
      accessorKey: "Explanation",
      header: translateForTransactionsTable("reports:explanation"),
      cell: ({ row }) => (
        <Link
          className="capitalize w-full block h-full"
          to={getViewUrl(row.original.id)}
        >
          {row.original.bankRecordTransactionExplanation?.length > 0 ? (
            <CheckIcon />
          ) : (
            <XIcon />
          )}
        </Link>
      ),
    },
  ];

  const {
    data: transactionsData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      "report_____",
      "transactions___",
      reportId,
      page,
      cacheKey,
      debouncedSearchQuery,
      filteredAccounts,
      rowsPerPage
    ],
    retry: false,
    queryFn: () =>
      fetchFunction(
        reportId,
        rowsPerPage,
        page * rowsPerPage,
        debouncedSearchQuery,
        filteredAccounts.length > 0
          ? [{ field: "account", value: [filteredAccounts] }]
          : [],
      ),
  });

  const table = useReactTable({
    data: isLoading ? Array(30).fill({}) : transactionsData?.data.transactions,
    columns: columns,
    rowCount: transactionsData?.data.total ?? 0,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: {
      pagination,
    },
    initialState: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const filterResult = filteredAccounts.map((e) => (
    <Badge
      key={uuidv4()}
      className={"bg-green-800 col-span-1 py-3 hover:bg-green-800"}
    >
      <div className="w-full flex justify-between items-center">
        {e}
        <Cross1Icon
          className="cursor-pointer"
          onClick={() => {
            setFilteredAccounts((prev) => prev.filter((i) => i !== e));
          }}
        />
      </div>
    </Badge>
  ));

  const addFilteredAccount = () => {
    const trimmedValue = filteredAccount.trim();
    if (trimmedValue !== "" && !filteredAccounts.includes(trimmedValue)) {
      setFilteredAccounts((prev) => [...prev, trimmedValue]);
      setFilteredAccount("");
      setPage(0);
    }
  };
  if (isUserLoading) {
    return <Loader/>
  }
  return (
    <>
      <div className="w-full relative bg-grayLightMainBg px-8">
        <div className="text-2xl mb-4">{title}</div>
        <div className="sticky left-0  z-10 w-full bg-grayLightMainBg">
          <>
            <div className="grid grid-flow-row-dense grid-cols-6 gap-4 mb-4">
              <Input
                className="col-span-12"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setPagination({
                    pageIndex: 0,
                    pageSize: rowsPerPage,
                  });
                }}
                placeholder={translateForTransactionsTable("search")}
              />
            </div>
          </>
          <>
            <div className="grid grid-flow-row-dense grid-cols-12 gap-4">
              <Input
                className="col-span-2"
                placeholder={translateForTransactionsTable(
                  "reports:filter_account",
                )}
                value={filteredAccount}
                onChange={(e) => {
                  setFilteredAccount(e.target.value);
                }}
              />
              <Button
                className={`col-span-1 ${filteredAccounts.length === 0 && filteredAccount.trim() === "" ? "bg-gray-500 hover:bg-gray-500" : ""}`}
                onClick={addFilteredAccount}
              >
                {translateForTransactionsTable("button_add")}
              </Button>
              {selectedTransaction.length > 0 && (
                <div className="w-[400px] col-span-2">
                  <BatchExplanationCreate ids={selectedTransaction} onComplete={() => {
                    setSelectedTransaction([])
                  }} />
                </div>
              )}
              {selectedTransaction.length > 0 && user.data.roles == ROLES.SUPER_ADMIN && toggleMatch && (
                <Button
                  className="col-span-1"
                  onClick={() => {
                    if (toggleMatch) {
                      toast({ title: "Started mark" });
                      toggleMatch(selectedTransaction).then((r) => {
                        toast({ title: "Ok" });
                        setSelectedTransaction([]);
                        refetch();
                      });
                     }
                    }
                  }
                >
                  Отметить 
                </Button>
              )}
            </div>
            <div className="grid grid-flow-row-dense grid-cols-6 gap-x-2 gap-y-2 mt-4 mb-4">
              {filterResult}
            </div>
          </>
          <div className="relative">
            <div className="sticky left-0  z-10 w-full bg-grayLightMainBg">
              <div className="flex justify-between  pr-8 items-center bg-grayLightMainBg">
                <div className="flex justify-end w-5/6"></div>
              </div>
            </div>
            <div className="w-full bg-grayLightMainBg">
              <div className="rounded-md border p-2">
                {
                  <Table>
                    <TableHeader>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={uuidv4()}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableHead className="pt-3 pb-3" key={uuidv4()}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext(),
                                    )}
                              </TableHead>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableHeader>
                    <TableBody>
                      {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && "selected"}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell
                                className="relative text-sm pt-3 pb-3"
                                key={uuidv4()}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                          >
                            {translateForTransactionsTable("no_results")}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                }
              </div>
              <DataTablePagination table={table} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

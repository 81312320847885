import { CONTRACT_STATES, CONTRACT_TYPES } from "@/constants";
import { getClients, getUser } from "@/service/weexpertService";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import { BaseDialog } from "./dialog";
import MultipleSelector from "../ui/multiple-selector";
import { DatePicker } from "../ui/date-picker";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { CheckIcon } from "@radix-ui/react-icons";
import MultipleSelectorWithSearch from "../ui/multiple-selector-with-search";
import { Loader } from "../ProtectedRoute";
const transformAutocompleteValueToFilter = (valueArray, field, key = 'id') => {
  return {
    field: field,
    value: valueArray.map(e => e[key])
  }
}

export const processContractFilter = (unprocessedFilter) => {
  const {
    selectedClients = [],
    selectedContractType = [],
    selectedStatus = [],
    startDatePeriod = [],
    dateOfCreatePeriod = [],
    dateOfEndPeriod = []
  } = unprocessedFilter;
  const clientsFilter = transformAutocompleteValueToFilter(selectedClients, 'client');
  const contractType = transformAutocompleteValueToFilter(selectedContractType, 'contractType')
  const contractStatus = transformAutocompleteValueToFilter(selectedStatus, 'contractStatus', 'value');
  return {
    client: clientsFilter,
    contractType: contractType,
    contractStatus: contractStatus,
    startDate: {
      field: 'startDate',
      value: startDatePeriod.filter(e => e).length == 2 ?
        startDatePeriod.filter(e => e).map(e => format(new Date(e), 'yyyy-MM-dd')) : []
    },
    createdAt: {
      field: 'conclusionDate',
      value: dateOfCreatePeriod.filter(e => e).length == 2 ?
        dateOfCreatePeriod.filter(e => e).map(e => format(new Date(e), 'yyyy-MM-dd')) : []
    },
    endDate: {
      field: 'endDate',
      value: dateOfEndPeriod.filter(e => e).length == 2 ?
        dateOfEndPeriod.filter(e => e).map(e => format(new Date(e), 'yyyy-MM-dd')) : []
    },
  }
}

export const ContractFilter = ({ onFilterChange, currentFilter }) => {

  const { t } = useTranslation();
  const [unprocessedFilters, setUnprocessedFilter] = useState({
    selectedClients: [],
    contractType: [],
    dateOfCreateFrom: null,
    dateOfCreateTo: null,
    startDateFrom: null,
    startDateTo: null,
    dateOfEndFrom: null,
    dateOfEndTo: null,
  });

  const { data: user, isLoading } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false })

  const [selectedClients, setSelectedClients] = useState(currentFilter.selectedClients ?? []);
  const [selectedContractType, setSelectedContractType] = useState(currentFilter.selectedContractType ?? []);
  const [selectedStatus, setSelectedStatus] = useState(currentFilter.selectedStatus ?? []);
  const [dateOfCreateFrom, setDateOfCreateFrom] = useState(
    currentFilter.dateOfCreatePeriod && currentFilter.dateOfCreatePeriod.filter(e => !!e).length === 2 ?
      new Date(currentFilter.dateOfCreatePeriod[0]) :
      null
  );
  const [dateOfCreateTo, setDateOfCreateTo] = useState(
    currentFilter.dateOfCreatePeriod && currentFilter.dateOfCreatePeriod.filter(e => !!e).length === 2 ?
      new Date(currentFilter.dateOfCreatePeriod[1]) :
      null
  );
  const [dateOfCreatePeriod, setDateOfCreatePeriod] = useState([dateOfCreateFrom, dateOfCreateTo]);
  const [startDateFrom, setStartDateFrom] = useState(
    currentFilter.startDatePeriod && currentFilter.startDatePeriod.filter(e => !!e).length === 2 ?
      new Date(currentFilter.startDatePeriod[0]) :
      null);
  const [startDateTo, setStartDateTo] = useState(currentFilter.startDatePeriod && currentFilter.startDatePeriod.filter(e => !!e).length === 2 ?
    new Date(currentFilter.startDatePeriod[1]) :
    null
  );
  const [startDatePeriod, setStartDatePeriod] = useState([startDateFrom, startDateTo]);

  const [dateOfEndFrom, setDateOfEndFrom] = useState(currentFilter.dateOfEndPeriod && currentFilter.dateOfEndPeriod.filter(e => !!e).length === 2 ?
    new Date(currentFilter.dateOfEndPeriod[0]) : null);
  const [dateOfEndTo, setDateOfEndTo] = useState(currentFilter.dateOfEndPeriod && currentFilter.dateOfEndPeriod.filter(e => !!e).length === 2 ?
    new Date(currentFilter.dateOfEndPeriod[1]) : null);
  const [dateOfEndPeriod, setDateOfEndPeriod] = useState([dateOfEndFrom, dateOfEndTo]);

  useEffect(() => {
    setUnprocessedFilter({
      selectedClients,
      dateOfCreateTo,
      dateOfCreateFrom,
      startDateFrom,
      startDateTo,
      dateOfEndTo,
      dateOfEndFrom,
      selectedContractType,
      selectedStatus,
      dateOfEndPeriod,
      startDatePeriod,
      dateOfCreatePeriod
    })
  }, [
    selectedClients,
    selectedContractType,
    startDatePeriod,
    dateOfCreatePeriod,
    dateOfEndPeriod,
    selectedStatus
  ]);

  useEffect(() => {
    setDateOfCreatePeriod([dateOfCreateFrom, dateOfCreateTo])
  }, [dateOfCreateFrom, dateOfCreateTo])

  useEffect(() => {
    setStartDatePeriod([startDateFrom, startDateTo])
  }, [startDateFrom, startDateTo])

  useEffect(() => {
    setDateOfEndPeriod([dateOfEndFrom, dateOfEndTo])
  }, [dateOfEndFrom, dateOfEndTo])


  const [open, setOpen] = useState(false);

  const isFilterNotEmpty = (filterData) => {
    return (Object.values(filterData).filter(e => e && (e && e.length > 0)).length > 0)
  }
  if (isLoading) {
    return <Loader />
  }
  return <BaseDialog
    dialogTitle={t('filter')}
    buttonText={t('filter')}
    open={open}
    setOpen={setOpen}
    icon={isFilterNotEmpty(currentFilter) ? <CheckIcon /> : null}
    ommitStyles={isFilterNotEmpty(currentFilter)}
  >
    <div className="w-[500px] px-2">

      {user.data.roles !== "CLIENT" && <div>
        <MultipleSelectorWithSearch
          fetchOption={(q) => {
            return getClients(10, 0, q)
              .then((result) => {
                return result.data.clients
                  .map(client => {
                    return {
                      label: `${client.firstName} ${client.lastName}`,
                      value: `${client.firstName} ${client.lastName}`,
                      id: client.id
                    }
                  })
              });
          }}
          className="min-h-10 mt-1 mb-2"
          placeholder={t("selected")}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedClients}
          label={t("clients")}
          onChange={(newSelected) => {
            setSelectedClients(newSelected)
          }}
        />
      </div>
      }
      <div>
        <MultipleSelector
          className="min-h-10 mt-1 mb-2"
          placeholder={t("selected")}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedStatus}
          label={t("status")}
          onChange={(newSelected) => {
            setSelectedStatus(newSelected)
          }}
          options={CONTRACT_STATES(t)}
        />
      </div>
      <div>
        <MultipleSelector
          className="min-h-10 mt-1 mb-2"
          placeholder={t("selected")}
          badgeClassName="bg-emerald-100 hover:bg-emerald-100 text-black"
          value={selectedContractType}
          label={t("type")}
          onChange={(newSelected) => {
            setSelectedContractType(newSelected)
          }}
          options={CONTRACT_TYPES(t)
            .map(item => {
              return {
                id: item.value,
                value: item.value,
                label: item.label
              }
            }
            )}
        />
      </div>
      <div className="flex justify-between pb-4">
        <div className="w-[48%]">
          <DatePicker date={dateOfCreateFrom}
            setDate={(e) => setDateOfCreateFrom(e)}
            label={t("contracts:date_of_conclusion_from")}
          />
        </div>
        <div className="w-[48%]">
          <DatePicker date={dateOfCreateTo}
            setDate={(e) => setDateOfCreateTo(e)}
            label={t("contracts:date_of_conclusion_to")}
          />
        </div>

      </div>
      <div className="flex justify-between pb-4">
        <div className="w-[48%]">
          <DatePicker date={startDateFrom}
            setDate={(e) => setStartDateFrom(e)}
            label={t("contracts:start_date_from")}
          />
        </div>
        <div className="w-[48%]">
          <DatePicker date={startDateTo}
            setDate={(e) => setStartDateTo(e)}
            label={t("contracts:start_date_to")}
          />
        </div>
      </div>
      <div className="flex justify-between pb-4">
        <div className="w-[48%]">
          <DatePicker date={dateOfEndFrom}
            setDate={(e) => setDateOfEndFrom(e)}
            label={t("contracts:end_date_from")}
          />
        </div>
        <div className="w-[48%]">
          <DatePicker date={dateOfEndTo}
            setDate={(e) => setDateOfEndTo(e)}
            label={t("contracts:end_date_to")}
          />
        </div>
      </div>
      <div className="w-full flex justify-end pt-6">
        <div className="w-full flex justify-end">
          <Button className="mr-4" variant="outline" onClick={() => {
            onFilterChange({})
            setOpen(false)
          }}>{t("common:button_clean_filter")}</Button>
          <Button className="" onClick={() => {
            onFilterChange(unprocessedFilters)
            setOpen(false)
          }}>{t("common:button_save")}</Button>
        </div>
      </div>
    </div>
  </BaseDialog>
}

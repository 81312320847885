import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { TransactionsTable } from "./bank-report-transactions-table.component";
import { batchMarkMatched, batchUnmarkMatched, getReport, getTransactionsForReport } from "@/service/weexpertService";
import { useQuery } from "react-query";
import { Loader } from "@/components/ProtectedRoute";
import { Button } from "@/components/ui/button";
import { t } from "i18next";

export const BankReportTitle = (props) => {
  const { reportId } = props;
  const { data, isLoading } = useQuery({ queryFn: () => getReport(reportId) });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="flex justify-between content-center items-center pr-16 pl-8 pt-16 mb-4">
      <div className="text-4xl">
        {data.data.report.title} {data.data.report.representativeCompany}
      </div>
      <Button asChild variant='outline'>
        <Link to={`/report/view/${reportId}`}>{t("common:button_back")}</Link>
      </Button>
    </div>
  );
};

export const AllTransactionsForReportPage = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <BankReportTitle reportId={id} />
      <TransactionsTable
        title={t("reports:report_transactions")}
        filterCacheKey="alltransaction"
        translateForTransactionsTable={t}
        reportId={id}
        fetchFunction={(reportId, take, skip, search) => {
          return getTransactionsForReport(reportId, take, skip, search, []);
        }}
        cacheKey="transaction"
      />
    </>
  );
};

export const UnmatchedTransactionsForReportPage = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <BankReportTitle reportId={id} />
      <TransactionsTable
        title={t("reports:unmatched_transaction")}
        translateForTransactionsTable={t}
        reportId={id}
        fetchFunction={(reportId, take, skip, search, filters = []) => {
          return getTransactionsForReport(
            reportId,
            take,
            skip,
            search,
            [{ field: "matched", value: ["false"] }].concat(filters),
          );
        }}

        toggleMatch={(ids) => batchMarkMatched(ids)}
        cacheKey="transaction"
      />
    </>
  );
};

export const MatchedTransactionsForReportPage = (props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <BankReportTitle reportId={id} />
      <TransactionsTable
        title={t("reports:matchet_transaction")}
        translateForTransactionsTable={t}
        reportId={id}
        fetchFunction={(reportId, take, skip, search, filters = []) => {
          return getTransactionsForReport(
            reportId,
            take,
            skip,
            search,
            [{ field: "matched", value: ["true"] }].concat(filters),
          );
        }}
        toggleMatch={(ids) => batchUnmarkMatched(ids)}
        cacheKey="transaction"
      />
    </>
  );
};
